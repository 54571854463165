import { gql } from '@apollo/client';

export const ALL_SPACE_USERS = gql`
  query allSpaceMembers($spaceId: String!) {
    allSpaceMembers(spaceId: $spaceId) {
      id
      name
      email
      avatar {
        path
      }
    }
  }
`;

export const MY_SPACES = gql`
  query getMySpaces($searchTerm: String) {
    mySpaces(searchTerm: $searchTerm) {
      id
      message
      name
      description
      cover_img {
        path
      }
      user {
          id
          email
        }
      stream_link 
      groups {
        id
        name
        stream_link 
        users {
          id
          user_id
          email
        }
        spaceMedia {
          uid
          url
          name
        }
      }
      opportunity {
        id
        title
        logo {
          path
        }
        user {
          id
          email
        }
      }
      
    }
  }
`;

export const GET_MY_GROUPS_QUERY = gql`
  query getMyGroups {
    myGroups {
      id
      space_id
      users {
        id
        user_id
        name
        avatar {
          path
        }
      }
     
    }
  }
`;

export const GET_SPACE = gql`
  query getSpace($id: String!) {
    space(id: $id) {
      id
      name
      description
      cover_img {
        path
      }
      user {
        id
        name
        email
      }
      message
      stream_link
      groups {
        id
        name
        stream_link
        users{
          id
          user_id
          name
          email
          avatar {
            path
          }
        }
        spaceMedia {
          uid
          url
          name
        }
      }
      spaceMedia {
        uid
        url
        name
      }
      organisation {
        id
        name
        user {
          name
          email
        }
      }
      opportunity{
        id
        title
        user {
          id
        }
        logo {
          path
        }
        cover {
          path
          name
        }
        media {
          path
          name
        }
        organisation {
          id
          name
          user {
            name
            email
          }
        }
      }
    }
  }
`;

export const GET_POSTS = gql`
  query spaceGroupPosts($groupId: String!) {
    spaceGroupPosts(groupId: $groupId) {
      id
      uid
      content
      media_url
      show_url
      user {
        id
        name
        email
        avatar {
          path
        }
      }
      group{
        id
        name
      }
      emoji_reactions {
        id
        uid
        emoji
        user_count
        users {
          id
          name
          email
          avatar {
            name
            path
            placeholder
            thumb
          }
        }
      }
      created_at
      comments_count
      is_pinned
    }
  }
`;

export const GET_MY_SPACE_GROUPS = gql`
  query mySpaceGroups($groupId: String!) {
    mySpaceGroups(groupId: $groupId) {
      id
      name
    }
  }
`;

export const GET_POST_COMMENTS = gql`
  query getPostComments($postId: String) {
    postComments(postId: $postId) {
      uid
      content
      comment_type
      child_post_url
      user {
        id
        name
        email
        avatar {
          path
        }
      }
      emoji_reactions {
        id
        uid
        emoji
        user_count
        users {
          id
          name
          email
          avatar {
            name
            path
            placeholder
            thumb
          }
        }
      }
    }
  }
`;

export const SEARCHSPACEMEMBERS = gql`
  query searchSpaceGroupMembers($spaceId: String!, $searchTerm: String!, $groupId: String) {
    searchSpaceGroupMembers(spaceId: $spaceId, searchTerm: $searchTerm, groupId: $groupId) {
      id
      name
      stream_link
      users {
        id
        user_id
        name
        email
        avatar {
          path
        }
      }
    }
  }
`;
