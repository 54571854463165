/* eslint-disable no-unused-vars */
import { useLazyQuery } from '@apollo/client';
import { GET_MY_GROUPS_QUERY, GET_POSTS } from '@app/apollo/queries';
import { useUser } from '@app/helpers/hooks';
import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';

const SpacesContext = createContext();
const socketUrl= process.env.NEXT_PUBLIC_SOCKET_URL

// eslint-disable-next-line react/prop-types
const SpacesProvider = ({ children }) => {

  const {user} = useUser();

  const [messages, setMessages] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [spaceDetail, setSpaceDetail] = useState(null);
  const [spaceGroups, setSpaceGroups] = useState([]);

  const [socket, setSocket] = useState(null)
  const [subscribedGroups, setSubscribedGroups] = useState([]);
  const [currentChatComments, setCurrentChatComments] = useState([]);

  const [globalUnreadCount, setGlobalUnreadCount] = useState(0);

  const spaceOrganisationEmail = spaceDetail?.opportunity?.organisation?.user?.email || spaceDetail?.organisation?.user?.email;
  const spaceOrganisationName = spaceDetail?.opportunity?.organisation?.name || spaceDetail?.organisation?.name;

  // refs
  const selectedGroupRef = useRef(selectedGroup)
  const authUserRef = useRef(user)

  useEffect(() => {
    selectedGroupRef.current = selectedGroup;
  }, [selectedGroup]);

  useEffect(() => {
    authUserRef.current = user;
  }, [user]);

  useEffect(() => {
    if (messages && spaceOrganisationEmail) {
      const userExists = messages.some(message => message.user.email === spaceOrganisationEmail);
      if (userExists) {
        setMessages(prevMessages => 
          prevMessages.map(message => ({
            ...message,
            user: {
              ...message.user,
              name: message.user.email === spaceOrganisationEmail
                    ? spaceOrganisationName
                    : message.user.name
            }
          }))
        );
      }
    }
  }, [messages?.length > 0, spaceOrganisationEmail]); // messages, spaceDetail

  useEffect(() => {
    if (currentChatComments && spaceOrganisationEmail) {
      const userExists = currentChatComments?.some(message => message.user.email === spaceOrganisationEmail);
      if (userExists) {
        setCurrentChatComments(prevMessages => 
          prevMessages.map(message => ({
            ...message,
            user: {
              ...message.user,
              name: message.user.email === spaceOrganisationEmail 
                    ? spaceOrganisationName
                    : message.user.name
            }
          }))
        );
      }
    }
  }, [currentChatComments?.length > 0, spaceOrganisationEmail]); // currentChatComments, spaceDetail

  useEffect(() => {
    if (selectedGroup?.users && spaceOrganisationEmail) {
      const userExists = selectedGroup?.users?.some(person => person.email === spaceOrganisationEmail);
      if (userExists) {
        setSelectedGroup(prev => ({
          ...prev,
          users: prev.users.map(person => ({
            ...person,
            name: person.email === spaceOrganisationEmail 
                  ? spaceOrganisationName
                  : person.name
          }))
        }));
      }
    }
  }, [selectedGroup?.users?.length > 0, spaceOrganisationEmail]); // selectedGroup, spaceDetail

  const listenForMessages = (group = null) => {
    socket.on(`newMessage`, (message) => {
      setGlobalUnreadCount((prevCount) => (prevCount + 1))
      if (selectedGroupRef.current?.id === message.space_group_id) {
        setMessages((prevMessages) => [...prevMessages, message]);
      }
    });
  };

  const listenForComments = (group = null) => {
    socket.on(`newComment`, (comment) => {
      setCurrentChatComments((prevComments) => [...prevComments, comment])
      if (selectedGroupRef.current?.id === comment.space_group_id) {
        setMessages((prevMessages) => prevMessages.map((msg) => {
          // If post_id and uid match, increment comments_count
          if (msg.uid === comment.post_id) {
              return {
                  ...msg,
                  comments_count: msg.comments_count + 1
              };
          }
          return msg;
        }));
      }
    });
  };

  const joinGroup = (group) => {
    socket.emit('joinGroup', group.id);
    // listenForMessages(group, user);
    // listenForComments(group, user);
  };

  const leaveGroup = (group) => {
    socket.emit('leaveGroup', group.id);
  };

  const [getMyGroupsQuery, { data: {myGroups} = [], loading: loadingMyGroups}] = 
  useLazyQuery(GET_MY_GROUPS_QUERY)

  useEffect(() => {
    if(user) {
      getMyGroupsQuery();
      authUserRef.current = user
    }
  }, [user])

  useEffect(() => {
    if(myGroups && socket && user) {
      // const newGroups = spaceDetail.groups;
      // const oldGroups = subscribedGroups;
  
      // const groupsToSubscribe = newGroups.filter(g1 => !oldGroups.some(g2 => g2.id === g1.id));
      // const groupsToUnsubscribe = oldGroups.filter(g1 => !newGroups.some(g2 => g2.id === g1.id));
  
      myGroups.forEach(group => {
        joinGroup(group);
      });
  
      // groupsToUnsubscribe.forEach(group => {
      //   leaveGroup(group);
      // });
  
      setSubscribedGroups(myGroups.myGroups);
    }
    return () => {
      if (subscribedGroups && socket) {
          subscribedGroups.forEach(group => {
              leaveGroup(group);
          });
      }
  };
  }, [myGroups, socket, user]);
  

  useEffect(() => {
    if(socketUrl) {
      const socketConn = io(socketUrl);
      socketConn.on('connect', () => { console.log('Connected to server')});
      setSocket(socketConn)
    }

    return () => {
      socket?.disconnect();
    };
  }, []);

  useEffect(() => {
    if( socket) {
      listenForMessages();
      listenForComments();
    }
  }, [socket])



  const [getPosts, { loading: loadingPosts }] = useLazyQuery(GET_POSTS);

  useEffect(() => {
    if(selectedGroup) {
      getPosts({
        variables: {
          groupId: selectedGroup.id
        },
        onCompleted: (res) => {
          if(res.spaceGroupPosts.length > 0) {
            const userExists = spaceOrganisationEmail && res.spaceGroupPosts?.some(message => message?.user?.email === spaceOrganisationEmail);
            if (userExists) {
              setMessages(res.spaceGroupPosts?.map(post => ({
                ...post,
                user: {
                  ...post.user,
                  name: post.user.email === spaceOrganisationEmail
                        ? spaceOrganisationName
                        : post.user.name
                }
              })));
            }else {
              setMessages(res.spaceGroupPosts)
            }
          } else {
            setMessages([])
          }
        }
      })
    }
  }, [selectedGroup])

  
  return (
    <SpacesContext.Provider
      value={{ 
        messages, setMessages,
        selectedGroup, setSelectedGroup,
        spaceDetail, setSpaceDetail,
        spaceGroups, setSpaceGroups,
        currentChatComments, setCurrentChatComments,
        globalUnreadCount, setGlobalUnreadCount,
        socket,
        loadingPosts,
      }}
    >
      {children}
    </SpacesContext.Provider>
  );
};

export const useSpaces = () => useContext(SpacesContext);

export default SpacesProvider;
